import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import spinner  from './MapComponents/Spinner.vue';

import { createI18n, useI18n } from 'vue-i18n';

import en from "./Translations/en.json";
import enUS from "./Translations/en-us.json";
import fr from "./Translations/fr.json";
import de from "./Translations/de.json";
import cn from "./Translations/cn.json";

import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate);

const i18n = createI18n({
	legacy: false,
	locale: 'en',
	fallbackLocale: 'en',
	messages: {
		fr,
		en,
		enUS,
		de,
		cn,
	}
});

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({ el, App, props, plugin }) {
		return createApp({ render: () => h(App, props) })
			.use(plugin)
			.use(i18n)
			.use(ZiggyVue)
			.use(pinia)
			.use(VueTippy)
			.component('spinner', spinner)
			.mount(el)
	},
	progress: {
		color: '#4B5563',
	},
});
